
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {TakipEntity} from "@/entity/TakipEntity";
import {BankaHesabiSahibiLabel} from "@/enum/BankaHesabiSahibi";

import TakipTalebiGenerator from "@/classes/evrak/TakipTalebi/TakipTalebiGenerator";
import FormWrapper from "@/components/FormWrapper.vue";
import Evrak from "@/classes/evrak/Evrak";
import {AlacakKalemiAlani} from "@/enum/AlacakKalemiAlani";
import KapakHesabi from "@/classes/evrak/KapakHesabi/KapakHesabi";
import KapakHesabiCalculator from "@/classes/evrak/KapakHesabi/KapakHesabiCalculator";
import {TakipDurumuLabel} from "@/enum/TakipDurumu";
import {HarcTuru} from "@/enum/HarcTuru";
import {ParaBirimi} from "@/enum/ParaBirimi";

@Component({
  components: {FormWrapper},
})
export default class OdemeTaahhuduTaslak extends Vue {
  @Prop() takip!: TakipEntity;
  @Prop() isKartNoVisible!: boolean;
  @Prop() vekaletSuretHarc!:boolean

  kapakHesabi: KapakHesabi = new KapakHesabi();
  ttEvrak: Evrak = new Evrak();
  bankaHesabiSahibiLabel = BankaHesabiSahibiLabel;
  alacakKalemiAlani = AlacakKalemiAlani;
  takipDurumu = TakipDurumuLabel;
  harcTuru = HarcTuru

  hesapAyar = JSON.parse(this.takip.taksit_hesap_ayar as unknown as string)

  headers = [
    {text: 'Taksit No', value: 'index'},
    {text: 'Tarih', value: 'tarih'},
    {text: 'Gün Sayısı', value: 'faiz_isleyen_gun'},
    {text: 'Faiz İşletilecek Tutar', value: 'faiz_isletilecek_tutar'},
    {text: 'İşleyecek Faiz', value: 'isleyen_faiz'},
    //{text: 'Toplam Faiz', value: 'toplamFaiz'},
    {text: 'Toplam Borç', value: 'kalan_borc'},
    {text: 'Taksit Miktarı', value: 'tutar'},
    {text: 'Kalan', value: 'kalan_ana_para'},
  ]

  get taksitToplamFaizIsleyenGun(){
    let toplam:number = 0
    this.takip.talepler[0].taksitler.forEach((taksit:any) => {
      toplam = toplam+taksit.faiz_isleyen_gun
    })
    return toplam
  }

  get toplamIsleyecekFaiz(){
    let toplam:number = 0.00
    this.takip.talepler[0].taksitler.forEach((taksit:any) => {
      toplam=toplam+Number(taksit.isleyen_faiz)
    })
    return toplam.toFixed(2)
  }

  get toplamtaksitMiktari(){
    let toplam:number = 0.00
    this.takip.talepler[0].taksitler.forEach((taksit:any) => {
      toplam=toplam+Number(taksit.tutar)
    })
    return toplam.toFixed(2)
  }

  mounted() {
    if (this.takip) {
      this.onTakipChange();
      this.calculateKapakHesabi();
      this.taksitToplamFaizIsleyenGun

    }
  }

  get dovizTahsilatlar(){
    let tahsilatlar :any = []
    let dovizToplami:number = 0

    this.takip.tahsilatlar.forEach(tah =>{
      tahsilatlar.push(tah)
    })
    tahsilatlar = tahsilatlar.filter((tah:any) => tah.para_birimi_id !== ParaBirimi.TRY);

    tahsilatlar.forEach((doviz:any) => {
      dovizToplami += doviz.tutar * doviz.kur;
    });
    return dovizToplami;
  }

  get tahsilHarcOrani(){
    let tahsilHarcOrani:any
    this.takip.talepler[0].alacaklar.forEach((alacak) => {
      alacak.alacak_kalemleri.forEach((kalemler) => {
        if (kalemler.harc_turu_id === this.harcTuru.tahsil_harci) {
          tahsilHarcOrani=kalemler.harc_orani;
        }
      })
    })
    if (tahsilHarcOrani==null){
      tahsilHarcOrani = "4.55"
    }
    return tahsilHarcOrani

  }

  calculateKapakHesabi() {
    if (this.takip.id) {
      let kapakHesabiCalculator: KapakHesabiCalculator = new KapakHesabiCalculator();
      kapakHesabiCalculator.setTakip(this.takip);
      this.kapakHesabi = kapakHesabiCalculator.calculateKapakHesabi();
      this.kapakHesabi.alacakKalemiAlanlari.sort(function (a, b) {
        return a - b;
      });
      this.kapakHesabi.takipSonrasialacakKalemiAlanlari.sort(function (a, b) {
        return a - b;
      });
    }
  }

  @Watch("takip")
  onTakipChange() {
    let takipTalebiGenerator = new TakipTalebiGenerator()
    takipTalebiGenerator.setTakip(this.takip);
    this.ttEvrak = takipTalebiGenerator.getTakipTalebiEvrak();
  }

  print() {
    this.$helper.printComponent('odemeTaahhuduTaslak');
  }
}
