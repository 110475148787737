
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import HarcHesabiTaslak from "@/components/TakipHarcYokTaslak.vue";
import { TakipEntity } from "@/entity/TakipEntity";
import TakipOncesiTaslak from "@/components/TakipOncesiTaslak.vue";
import TakipHarcYokTaslak from "@/components/TakipHarcYokTaslak.vue";
import HarcHesapTablosuTaslak from "@/components/HarcHesapTablosuTaslak.vue";
import TakipHarcVarTaslak from "@/components/TakipHarcVarTaslak.vue";
import TakipTalebiTaslak from "@/components/TakipTalebiTaslak.vue";
import { ParaBirimi } from "@/enum/ParaBirimi";

@Component({
  components: {
    TakipTalebiTaslak,
    TakipHarcVarTaslak,
    HarcHesapTablosuTaslak,
    TakipHarcYokTaslak,
    TakipOncesiTaslak,
    HarcHesabiTaslak,
    FormWrapper,
  },
})
export default class HesapTablosuForm extends Mixins(ObjectInputMixin) {
  @Prop() takip!: TakipEntity;
  @Ref("takipHarcYokTaslak") readonly takipHarcYokTaslak!: TakipHarcYokTaslak;
  @Ref("takipOncesiHesabiTaslak") readonly takipOncesiHesabiTaslak!: TakipOncesiTaslak;
  @Ref("harcHesapTablosuTaslak") readonly harcHesapTablosuTaslak!: HarcHesapTablosuTaslak;
  @Ref("takipHarcVarTaslak") readonly takipHarcVarTaslak!: TakipHarcVarTaslak;

  @Prop() hesapTarihi!: Date;

  tab = 0;
  takipOncesiTutar: boolean = false;
  harcHesabi: boolean = false;

  get dovizTahsilatlar() {
    let tahsilatlar: any = [];
    let dovizToplami: number = 0;

    this.takip.tahsilatlar.forEach((tah) => {
      tahsilatlar.push(tah);
    });
    tahsilatlar = tahsilatlar.filter((tah) => tah.para_birimi_id !== ParaBirimi.TRY);

    tahsilatlar.forEach((doviz) => {
      dovizToplami += doviz.tutar * doviz.kur;
    });
    return dovizToplami;
  }

  print() {
    //yazdırda üst ve alttaki tarih/about:blank yazılarını kaldırır
    const header = document.querySelector("header");
    const footer = document.querySelector("footer");
    if (header) header.style.display = "none";
    if (footer) footer.style.display = "none";

    let componentToPrint;
    if (!this.takipOncesiTutar && !this.harcHesabi) {
      componentToPrint = this.takipHarcYokTaslak;
    } else if (this.takipOncesiTutar && !this.harcHesabi) {
      componentToPrint = this.takipOncesiHesabiTaslak;
    } else if (!this.takipOncesiTutar && this.harcHesabi) {
      componentToPrint = this.harcHesapTablosuTaslak;
    } else if (this.takipOncesiTutar && this.harcHesabi) {
      componentToPrint = this.takipHarcVarTaslak;
    }

    if (componentToPrint && typeof componentToPrint.print === 'function') {
      this.printComponent(componentToPrint);
    } else {
      console.error('Yazdırılacak bileşen bulunamadı veya print metodu tanımlı değil.');
    }

    setTimeout(() => {
      if (header) header.style.display = '';
      if (footer) footer.style.display = '';
    }, 1000);
  }

  printComponent(component: any) {
    const printWindow = window.open('', '_blank');
    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head>
            <title>Yazdır</title>
            <style>
              @media print {
                body {
                  font-family: Arial, sans-serif;
                  font-size: 12pt;
                }
                table {
                  width: 100%;
                  border-collapse: collapse;
                  page-break-inside: auto;
                }
                tr {
                  page-break-inside: avoid;
                  page-break-after: auto;
                }
                td, th {
                  padding: 8px;
                  border: 1px solid #000;
                }
                thead {
                  display: table-header-group;
                }
                tfoot {
                  display: table-footer-group;
                }
              }
            </style>
          </head>
          <body>
            ${component.$el.outerHTML}
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    }
  }

  async wordYazdir() {
    let belge: string = "";
    let contentElement;

    if (!this.takipOncesiTutar && !this.harcHesabi) {
      belge = "takipHarcYokTaslak";
      contentElement = this.takipHarcYokTaslak.$el;
    } else if (this.takipOncesiTutar && !this.harcHesabi) {
      belge = "takipOncesiHesabiTaslak";
      contentElement = this.takipOncesiHesabiTaslak.$el;
    } else if (!this.takipOncesiTutar && this.harcHesabi) {
      belge = "harcHesapTablosuTaslak";
      contentElement = this.harcHesapTablosuTaslak.$el;
    } else if (this.takipOncesiTutar && this.harcHesabi) {
      belge = "takipHarcVarTaslak";
      contentElement = this.takipHarcVarTaslak.$el;
    }

    if (contentElement) {
      const header =
          '<!DOCTYPE html>' +
          '<html xmlns:o="urn:schemas-microsoft-com:office:office" ' +
          'xmlns:w="urn:schemas-microsoft-com:office:word" ' +
          'xmlns="http://www.w3.org/TR/REC-html40">' +
          '<head>' +
          '<meta charset="utf-8">' +
          '<meta http-equiv="Content-Type" content="text/html; charset=utf-8">' +
          '<style>' +
          'table { border-collapse: collapse; width: 100%; }' +
          'td, th { border: 1px solid black; padding: 8px; }' +
          '</style>' +
          '</head><body>';

      const footer = "</body></html>";
      const content = contentElement.outerHTML;
      const sourceHTML = header + content + footer;

      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), sourceHTML], {
        type: 'application/msword;charset=utf-8'
      });

      const url = URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);
      downloadLink.href = url;
      downloadLink.download = `${belge}.doc`;
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(url);
    }
  }
}
