
import { Component, Prop, Vue } from "vue-property-decorator";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue"
import FormDialog from "@/components/dialogs/FormDialog.vue";
import { TakipEntity } from "@/entity/TakipEntity";
import BorcluCardInfo from "@/components/infos/BorcluCardInfo.vue";
import BorcluForm from "@/components/forms/BorcluForm.vue";

@Component({
  components: { BorcluCardInfo, BorcluForm, FormDialog, DeleteDialog }
})
export default class BorcluCardList extends Vue {
  @Prop({ required: true }) takipId!: number;
  @Prop({ default: false }) takipDetay!: boolean;
  @Prop() items!: TakipEntity;

  shortView: boolean = true;

  get visibleBorclular() {
    return this.shortView ? this.items.borclular.slice(0, 3) : this.items.borclular;
  }

  toggleView() {
    this.shortView = !this.shortView;
  }

  load() {
    this.$emit("load");
  }
}
