
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import Evrak from "@/classes/evrak/Evrak";
import {TakipEntity} from "@/entity/TakipEntity";
import KapakHesabi from "@/classes/evrak/KapakHesabi/KapakHesabi";
import {AlacakKalemiAlani} from "@/enum/AlacakKalemiAlani";
import TakipTalebiGenerator from "@/classes/evrak/TakipTalebi/TakipTalebiGenerator";
import KapakHesabiCalculator from "@/classes/evrak/KapakHesabi/KapakHesabiCalculator";
import HesapTablosuInfo from "@/components/infos/HesapTablosuInfo.vue";

@Component({
  components: {HesapTablosuInfo, FormWrapper}
})
export default class TakipOncesiTaslak extends Vue{
  @Prop() takip!: TakipEntity;
  @Prop() hesapTarihi!:Date
  @Prop () dovizTahsilat!:number

  AlacakKalemiAlani = AlacakKalemiAlani;
  kapakHesabi: KapakHesabi = new KapakHesabi();
  ttEvrak: Evrak = new Evrak();

  mounted() {
    if (this.takip) {
      this.onTakipChange();
      this.calculateKapakHesabi()
    }
  }

  get toplamFaizTutar(){
    let toplam = 0;
    this.kapakHesabi.duzenlenmisTakipSonrasiKalemler.forEach((kalemler:any) => {
      if (kalemler.id === this.AlacakKalemiAlani.takipSonrasiFaiz) {
        toplam += parseFloat(kalemler.tutar);
      }
    });
    return toplam.toFixed(2);
  }
  calculateKapakHesabi() {
    if (this.takip.id) {
      let kapakHesabiCalculator: KapakHesabiCalculator = new KapakHesabiCalculator();
      kapakHesabiCalculator.setTakip(this.takip);
      this.kapakHesabi = kapakHesabiCalculator.calculateKapakHesabi();
      this.kapakHesabi.alacakKalemiAlanlari.sort(function (a, b) {
        return a - b;
      });
      this.kapakHesabi.takipSonrasialacakKalemiAlanlari.sort(function (a, b) {
        return a - b;
      });
    }
  }
  @Watch("takip")
  onTakipChange() {
    let takipTalebiGenerator = new TakipTalebiGenerator()
    takipTalebiGenerator.setTakip(this.takip);
    this.ttEvrak = takipTalebiGenerator.getTakipTalebiEvrak();
  }

  print() {
    this.$helper.printComponent('takipOncesiHesabiTaslak');
  }

}
