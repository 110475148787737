
import {Component, Mixins, Prop, Ref, Watch} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import TahsilatTuruPicker from "@/components/pickers/TahsilatTuruPicker.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import Kur from "@/components/inputs/Kur.vue";
import {TahsilatTuru, TahsilatTuruLabel} from "@/enum/TahsilatTuru";
import TakipBorcluPicker from "@/components/pickers/TakipBorcluPicker.vue";
import TahsilHarciPicker from "@/components/pickers/TahsilHarciPicker.vue";
import AltTahsilatTuruPicker from "@/components/pickers/AltTahsilatTuruPicker.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import {ParaBirimi} from "@/enum/ParaBirimi";
import {TakipDurumu} from "@/enum/TakipDurumu";
import {TakipEntity} from "@/entity/TakipEntity";
import {HarcTuruEntity} from "@/entity/HarcTuruEntity";
import {AxiosResponse} from "axios";
import {HarcDonemiEntity} from "@/entity/HarcDonemiEntity";
import {AltTahsilatTuru} from "@/enum/AltTahsilatTuru";

@Component({
  components: {
    Kur,
    FormWrapper,
    AltTahsilatTuruPicker,
    TahsilHarciPicker,
    TakipBorcluPicker,
    Tutar,
    TahsilatTuruPicker,
    Dates,
    ParaBirimiPicker
  }
})
export default class TahsilatForm extends Mixins(ObjectInputMixin) {
  @Prop({required: true}) takip!: TakipEntity;
  @Ref('takipBorcluPicker') readonly takipBorcluPicker!: TakipBorcluPicker;

  hideSubmitButton:boolean=false

  TahsilatTuru = TahsilatTuru;
  ParaBirimi = ParaBirimi;
  TakipDurumu = TakipDurumu;
  items: Array<HarcDonemiEntity> = [];
  selectedItem: HarcDonemiEntity = new HarcDonemiEntity();
  altTahsilatTuru = AltTahsilatTuru;
  takipSonrasi: boolean = false;
  tarihKontrol: boolean = false;
  isHesaplaniyor: boolean = false;
  harctanMuafMiOranAlert:boolean = false

  get isHideButtonsSubmit(){
    if (this.takip.takip_durumu==TakipDurumu.acilmamis){
      if (this.tarihKontrol){
        return this.hideSubmitButton = true
      }else{
        return this.hideSubmitButton = false
      }
    }
  }

  @Watch("localValue.id")
  onItemChange() {
    if (!!this.localValue.id) {
      this.localValue.net_tutar = Number(this.value.net_tutar);
    }
  }

  @Watch('takip.borclular')
  onBorcluChange() {
    this.takipBorcluPicker.load();
  }

  @Watch('localValue.tahsilat_turu')
  changeTahsilatTuru(){
    if (this.localValue.tahsilat_turu!==this.TahsilatTuru.icra){
      this.localValue.cezaevi_harci=0
    }
      this.netTutarHesapla()

  }
  load() {
    this.$emit('load');
  }

  birGunCikar() {
    let gun = new Date(this.takip.takip_tarihi).setDate(new Date(this.takip.takip_tarihi).getDate() - 1)
    let myDate = new Date(gun).toISOString().substr(0, 10);
    return myDate
  }

  get filterTurler(){
    if (new Date(this.localValue.tarih).getTime() < new Date(this.takip.takip_tarihi).getTime()){
      const filteredTurler = Object.values(TahsilatTuru)
        .filter(turu => turu !== TahsilatTuru.icra);
      return filteredTurler;
    } else {
      return Object.values(TahsilatTuru);
    }
  }

  get filterTurlerLabel(){
    if (new Date(this.localValue.tarih).getTime() < new Date(this.takip.takip_tarihi).getTime()){
      const filteredLabels = Object.values(TahsilatTuruLabel)
        .filter((label, index) => Object.values(TahsilatTuru)[index] !== TahsilatTuru.icra);
      return filteredLabels;
    } else {
      return Object.values(TahsilatTuruLabel);
    }

  }


  @Watch('localValue.tarih')
  onTahsilatTarihi() {

    if (new Date(this.localValue.tarih).getTime() > new Date(this.takip.takip_tarihi).getTime()) {
      this.tarihKontrol = true;
      this.input();
    } else {
      this.tarihKontrol = false;
    }
    if (this.takipSonrasi && new Date(this.localValue.tarih).getTime() < new Date(this.takip.takip_tarihi).getTime()) {
      this.localValue.tahsil_harci_orani = "0.00";
      this.input();
    } else if (this.takipSonrasi && new Date(this.localValue.tarih).getTime() >= new Date(this.takip.takip_tarihi).getTime()) {
      this.localValue.tahsil_harci_orani = "4.55";
      this.input();
    }
  }

  @Watch('localValue.tutar')
  onTahsilatTutarChange() {
    if (!this.takipSonrasi) {
      this.localValue.net_tutar = Number(this.localValue.tutar)
    }
  }


  mounted() {
    if (this.takip.takip_durumu != TakipDurumu.acilmamis) {
      this.takipSonrasi = true;
    }
    if (!this.localValue.tutar) {
      this.localValue.net_tutar = 0;
    }
    if (!this.localValue?.id) {
      this.localValue.tarih = new Date();
      this.localValue.giris_tarihi = new Date();
      this.localValue.alt_tahsilat_turu_id = null;
    }
    this.getHarcDonemi();
  }

  @Watch("localValue.tutar")
  onTutarChange() {
    if (this.takipSonrasi)
      this.onTahsilHarciOraniChange();
  }

  onKurChange() {
    if (this.takipSonrasi) {
      this.onTahsilHarciOraniChange();
      this.harcHesapla();
    } else
      this.input();
  }

  harcHesapla() {
    if (this.takipSonrasi) {
      if (!this.localValue.tutar)
        this.localValue.net_tutar = 0;
      if (this.localValue.tutar &&
          this.localValue.tahsil_harci &&
          (this.localValue.para_birimi_id == ParaBirimi.TRY || this.localValue.kur) &&
          !this.localValue?.id) {
        if (this.localValue.para_birimi_id == ParaBirimi.TRY) {
          this.localValue.kur = 1;
        }
        if (this.localValue.tahsilat_turu == this.TahsilatTuru.icra) {
          this.netTutarHesapla();
        } else {
          this.localValue.net_tutar = this.localValue.tutar;
        }
      }
      this.input();
    }
  }

  changeTahsilHarcOran(){
    if (this.localValue.tahsil_harci_orani != "0.00" && this.takip.alacaklilar.some(item => item.muvekkil.kurum.harc_muaf_mi)){
      this.harctanMuafMiOranAlert = true
    }else if(this.localValue.tahsil_harci_orani == "0.00" || this.takip.alacaklilar.some(item => !item.muvekkil.kurum.harc_muaf_mi)){
      this.harctanMuafMiOranAlert = false;
    }
  }

  async onTahsilHarciOraniChange() {
    this.isHesaplaniyor = true;
    this.localValue.cezaevi_harci = 0;
    if (this.localValue.para_birimi_id == ParaBirimi.TRY)
      this.localValue.kur = 1;
    // this.localValue.tahsil_harci = (Number(this.localValue.tutar ?? 0) / 100 * Number(this.localValue.tahsil_harci_orani ?? 1)) * (this.localValue.kur ?? 1);
    if (this.localValue.tutar) {
      let response = await this.$http.post('/api/v1/takip/' + this.takip.id + '/tahsil-harci-hesapla', {
        tahsilat_id:this.localValue.id ?? null,
        tutar: this.localValue.tutar,
        tahsil_harci_orani: this.localValue.tahsil_harci_orani,
        kur: this.localValue.kur
      }).catch(() => (this.isHesaplaniyor = false));
      if (typeof response !== "boolean") {
        this.localValue.tahsil_harci = response.data.tahsil_harci_tutari;
      }
    }
    this.netTutarHesapla();
    this.isHesaplaniyor = false;
    this.input();
  }

  netTutarHesapla() {
    let tutar = this.localValue.tutar ?? 0;
    if (typeof tutar == 'string') tutar = Number(tutar);
    if (this.localValue.para_birimi_id != ParaBirimi.TRY) tutar *= this.localValue.kur;
    if (!this.takipSonrasi) {
      this.localValue.net_tutar = tutar;
    } else if (this.localValue.tahsilat_turu == this.TahsilatTuru.icra && this.takipSonrasi) {
      if (this.localValue.tahsil_harci_orani == 0){
        this.localValue.net_tutar = this.localValue.tutar;
      }

        this.localValue.cezaevi_harci = tutar * 0.02;
        this.localValue.net_tutar = tutar - (Number(this.localValue.tahsil_harci) + this.localValue.cezaevi_harci);

      if (this.localValue.para_birimi_id != ParaBirimi.TRY)
        this.localValue.net_tutar /= this.localValue.kur;
    }
    else {
      this.localValue.net_tutar = tutar - Number(this.localValue.tahsil_harci);
      if (this.localValue.para_birimi_id != ParaBirimi.TRY)
        this.localValue.net_tutar /= this.localValue.kur;
    }
    if (isNaN(this.localValue.net_tutar)){
      this.localValue.net_tutar=0
    }
  }

  getHarcDonemi() {
    this.$http.get("/api/v1/tahsilat-harclar").then((response) => {
      this.items = response.data;
    });
  }

  get tahsilHarciOranlari(){
    let tahsilHarc:any=[]
    this.items.forEach((item: any) => {
      if(item.oran==2.275){
        tahsilHarc.push(Number(item.oran).toFixed(3).toString());
      }else {
        tahsilHarc.push(Number(item.oran).toFixed(2).toString());
      }
      tahsilHarc.push("0.00")
    })
    if (this.takip.alacaklilar.some(item => item.muvekkil.kurum.harc_muaf_mi)){
      this.localValue.tahsil_harci_orani = "0.00";
    }
    return tahsilHarc
  }

  /*get tahsilHarciOranlari() {
    let harclar !: HarcTuruEntity[];
    harclar = this.selectedItem.harclar;
    if (!harclar) {
      return null;
    }
    let harcTurleri = harclar.filter((x: any) => x.harc_turu.harc_tipi === "tahsil")
    let harcOranlari: any = [];
    harcTurleri.forEach((harcTuru) => {
      if (harcTuru.oran==2.275){
        harcOranlari.push(harcTuru.oran)
      }else{
        harcOranlari.push(Number(harcTuru.oran).toFixed(2));
      }
      harcOranlari.push("0.00");
      harcOranlari.push("0.90");
      harcOranlari.push("1.80");
      harcOranlari.push("1.98");
      harcOranlari.push("2.27");
      harcOranlari.push("3.60");
      harcOranlari.push("3.96");
      harcOranlari.push("5.00");
      harcOranlari.push("7.20");
      harcOranlari.push("7.92");
      harcOranlari.push("9.00");
      harcOranlari.push("9.90");
    });
    return harcOranlari;
  }*/

  save() {
    if (this.localValue.id) {
      this.$http.put("/api/v1/tahsilat/" + this.localValue.id, this.localValue).then(() => {
        this.$emit('onSuccess');
      })
    } else {
      this.$http.post("/api/v1/takip/" + this.takip?.id + "/tahsilat/", this.localValue).then(() => {
        this.$emit('onSuccess');
      })
    }
  }
}
